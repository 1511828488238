body,
html {
  /* min-width: 1440px; */
  margin: 0;
  padding: 0;
  width: 100%;

  font-family: 'MI Lan Pro', serif;
  font-weight: 400;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
ul {
  margin: 0;
  padding: 0;
}
/* @media screen and (max-width: 1440px) {
  .bodys {
    zoom: 90%;
  }
} */
code {
  font-family: MI Lan Pro, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
@font-face {
  font-family: 'MitypeMono-SemiBold';
  src: url('./assets/font/MitypeMono-SemiBold.woff') format('woff');
}
