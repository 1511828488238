@import 'style/_global';
.head {
  // text-align: right;
  position: relative;
  // max-width: 1200px;
  height: pxToRem(27);
  top: pxToRem(21);
  font-weight: 440;
  font-size: pxToRem(20);
  line-height: pxToRem(27);
  z-index: 1000;
  // width: 1180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  i {
    float: left;
    width: pxToRem(25);
    clear: right;
    img {
      width: 100%;
    }
  }
  span {
    float: left;
    margin-left: pxToRem(20);
    font-size: pxToRem(20);
    line-height: pxToRem(27);
  }
  .spanDark {
    color: #ffffff;
  }
  .spanLight {
    color: #000000;
  }
  ul {
    // width: pxToRem(351);
    // padding-left: 62%;
    float: right;
    align-items: flex-end;
  }
  ul li {
    display: inline-block;
    list-style: none;
    // align-content: flex-end;
    width: auto;
    margin-right: 0;
    margin-left: pxToRem(50);
  }
  .dark {
    text-decoration: none;
    color: rgba(168, 164, 164, 0.4);
    &:hover {
      color: #ffffff;
    }
  }
  .light {
    text-decoration: none;
    color: rgba(68, 67, 67, 0.4);
    &:hover {
      color: #000000;
    }
  }
  ::after {
    content: '';
    clear: both;
  }
}
