@import 'style/_global';
.suggestionWrapper,
.specialWrapper,
.summerWrapper,
.unionWrapper,
.dialogWrapper,
.helpWrapper,
.infinityWrapper {
  width: 50%;
  margin: 120px auto;
  img {
    width: 100%;
  }
  video {
    width: 100%;
    height: auto;
    // poster占满屏幕
    object-fit: fill;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.helpWrapper{
  img{
    width: pxToRem(432);
  }
}

.title {
  font-size: 48px;
  line-height: 64px;
}
.time {
  font-size: 20px;
  line-height: 27px;
  opacity: 0.5;
  margin-bottom: 35px;
}
.content {
  font-size: 22px;
  line-height: 36px;
  opacity: 0.8;
  .subtitle {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 36px;
    text-align: start;
  }
}
.subtext {
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  opacity: 0.8;

  margin-bottom: 72px;
}
.dialogWrapper {
  img {
    -webkit-filter: saturate(150%); /* Chrome, Safari, Opera */
    filter: saturate(150%);
  }
}

a{
  text-decoration: underline;
  color:#000000;
  cursor: pointer;
}
a:visited{
  color:#000000;
}

