@import 'style/_global';
.wrapper {
  padding-top: 30px;
  margin-top: 200px;
  height: pxToRem(80);
  // height: 80px;
  background-color: #000000;
  .link {
    display: flex;
    justify-content: center;
    font-family: MI Lan Pro;
    font-style: normal;
    font-weight: normal;
    font-size: pxToRem(14);
    // font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .wechat {
    cursor: pointer;
    position: relative;
  }
  .wechatImg {
    z-index: 9999;
    width: pxToRem(149);
    // width: 149px;
    height: pxToRem(149);
    // height: 149px;
    position: absolute;
    top: pxToRem(-159);
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../../assets/img/wechat.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: none;
  }

  .wechat:hover > .wechatImg {
    display: block;
  }

  p {
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top: pxToRem(9);
    font-family: MI Lan Pro;
    font-style: normal;
    font-weight: normal;
    font-size: pxToRem(12);
    // font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
  }
}
