.wrapper {
  // width: 1900px;
  // width: 100%;
  animation: move infinite 30s linear;
  &.reverse {
    animation: moveReverse infinite 30s linear;
  }
}

@keyframes move {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes moveReverse {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
