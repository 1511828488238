@import 'style/_global';
.wrapper {
  position: relative;
  height: pxToRem(1080);
  // height: 56.25vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  top: 0px;
  background: url(../../assets/img/homepage/bg.png) left bottom;
  background-size: cover;
}
.top {
  // width: 1180px;
  width: 62%;
  // max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.topContent {
  position: relative;
  // width: 940px;
  width: 80%;
  margin-left: 0;
  // margin-right: auto;
  top: pxToRem(210);

  p {
    font-family: MI Lan Pro;
    font-style: normal;
    font-weight: 480;
    // font-size: 30px;
    font-size: pxToRem(30);
    line-height: pxToRem(40);
    color: #6dd8dc;
  }
  h2 {
    height: pxToRem(190);
    font-family: MI Lan Pro;
    font-style: normal;
    font-weight: 540;
    // font-size: 68px;
    font-size: pxToRem(68);
    // line-height: 95px;
    line-height: pxToRem(95);
    color: #ffffff;
    margin-top: pxToRem(30);
    margin-bottom: pxToRem(30);
    padding-bottom: 0;
  }
  div {
    // font-size: 24px;
    font-size: pxToRem(24);
    // line-height: 42px;
    line-height: pxToRem(42);
    color: rgba(255, 255, 255, 0.4);
  }
}
